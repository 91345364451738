import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { ScreenSizes, Sx } from "../../../types/responsive";
import { StyledDiv, StyledImg, StyledSection } from "../StyledSx";

const Cta = styled(StyledSection)`
  padding-bottom: 80px;
  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const CtaWrapper = styled(StyledDiv)`
  background-image: linear-gradient(-56deg, #12e0c2 0%, #aab4e9 41%);
  border-radius: 16px;
  padding: 80px 98px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: ${ScreenSizes.lg}) {
    padding: 80px 60px;
  }
  @media (max-width: ${ScreenSizes.sm}) {
    padding: 40px 20px;
  }
`;

const CtaContent = styled(StyledDiv)`
  display: flex;
  flex-direction: column;
  width: 680px;
  @media (max-width: ${ScreenSizes.lg}) {
    width: 630px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    width: 100%;
    align-items: center;
  }
`;

interface Props {
  wrapperStyle?: Sx;
  contentStyle?: Sx;
  sx?: Sx;
}

export const GradientSectionDescription = styled.div`
  font-weight: 500;
  color: #fafafa;
  font-size: 16px;
  p {
    font-size: 1.25rem;
    @media (max-width: ${ScreenSizes.sm}) {
      font-size: 1rem;
    }
  }
  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;

export const GradientCtaImage = styled(StyledImg)`
  position: absolute;
  bottom: -6px;
  right: 98px;
  width: 19.5%;

  @media (max-width: ${ScreenSizes.lg}) {
    bottom: 0;
    width: 240px;
    right: 60px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;

export const GradientBox: React.FC<Props & PropsWithChildren<any>> = ({children, wrapperStyle, contentStyle, sx}) => {
  return (
    <Cta sx={sx}>
      <CtaWrapper sx={wrapperStyle}>
        <CtaContent sx={contentStyle}>{children}</CtaContent>
      </CtaWrapper>
    </Cta>
  );
};
