import styled from 'styled-components';
import {StyledDiv} from './StyledSx';
import {ScreenSizes} from '../../types/responsive';

type Props = {
  padding?: string;
  flat?: boolean;
  borderless?: boolean;
};

export const Card = styled(StyledDiv)<Props>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1 0 max-content;
  background: #ffffff;
  border: ${props => props.borderless ? 'none' : '1px solid #eeeeee'};
  border-radius: 5px;
  padding: ${(props) => props.padding || '106px 32px 52px 32px'};
  box-shadow: ${(props) =>
    props.flat
      ? 'none'
      : `0px 64px 64px rgba(61, 88, 128, 0.05), 
         0 32px 32px rgba(61, 88, 128,0.05),
         0 16px 16px rgba(61, 88, 128, 0.05),
         0 8px 8px rgba(61, 88, 128, 0.05),
         0 4px 4px rgba(61, 88, 128, 0.05), 
         0 2px 2px rgba(61, 88, 128, 0.05)`};

  h4 {
    font-size: 1.3rem;
    margin-bottom: 0.875rem;
    max-width: 12.5rem;
    text-overflow: ellipsis;
  }

  p {
    margin-bottom: 0;
  }

  @media (max-width: ${ScreenSizes.xs}) {
    width: 100%;
  }
`;

export const CardWrapper = styled.div`
  max-width: 230px;
`;

export const CardImage = styled.img<{position?: 'absolute' | 'relative'}>`
  position: ${(props) => props.position || 'absolute'};
`;
