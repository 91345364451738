// i18next-extract-mark-ns-start payment-gateway-france

import {Background} from 'components/Background';
import {ButtonLink} from 'components/Button';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {IndexBackground} from 'components/landings/IndexBackground';
import {List} from 'components/List';
import {Partners} from 'components/Partners';
import {PaymentMethods} from 'components/PaymentMethods';
import {Section, SectionActions, SectionHeader} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';
import styled from 'styled-components';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';
import {PrismicPage} from 'components/links/PrismicPage';
import check_green from 'images/check_green.svg';
import {Card, CardImage} from 'components/Card';
import cards from 'images/credit-cards.svg';
import cart from 'images/cart.svg';
import dashboard from 'images/exchange-platform.svg';
import {SupportLink} from 'components/links/Support';
import clock from 'images/clock.svg';
import person from 'images/person.svg';
import security from 'images/security.svg';
import CardsContainer from 'components/CardsContainer';
import payment_gateway from 'images/payment_gateway.svg';
import {GradientBox} from 'components/landings/GradientBox';
import { ScreenSizes } from "../../types/responsive";
import baloon from 'images/baloon.svg';

const IndexContent = styled.div`
  padding-top: 0;
  padding-bottom: 0;
  max-width: 600px;
  flex: 3;
  @media (max-width: ${ScreenSizes.lg}) {
    max-width: 560px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    max-width: 100%;
  }
`;

const IndexSection = styled(Section)`
  padding-bottom: 0;
  padding-top: 50px;
  align-items: center;
  @media (max-width: 1440px) {
    padding-top: 10%;
    padding-bottom: 0;
  }
  @media (max-width: 1024px) {
    padding-top: 10%;
    padding-bottom: 0;
  }
  @media (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 80px;
  }
  .index-image {
    margin-right: -50px;
    width: 600px;
    flex: 2;
    @media (max-width: ${ScreenSizes.lg}) {
      display: none;
    }
  }
`;

const CheckGreen = styled.img`
  margin: 4px 8px 0 0;
`;

const SectionDescription = styled.p`
  font-weight: 500;
  color: #fafafa;
  font-size: 16px;
  p {
    font-size: 1.25rem;
  }
  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;

const CtaImage = styled.img`
  position: absolute;
  bottom: -6px;
  right: 98px;
  width: 19.5%;

  @media (max-width: ${ScreenSizes.lg}) {
    bottom: 0;
    width: 240px;
    right: 60px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;
export const PaymentCard = styled.div`
  flex-basis: 100%;
  max-width: 1440px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 768px) {
    padding: 40px 32px;
  }
`;

export const CardWrapper = styled.div`
  max-width: 280px;
  h4 {
    max-width: 100%;
  }
`;

const PaymentGatewayFrance: React.FC = () => {
  const {t} = useI18next();

  const title = t('Sell in France - Expand Your E-commerce Business');
  const description = t(
    'France is the 3rd largest market in Europe, with a thriving e-commerce sector. Expand internationally. Accept cross-border payments. Sell in France with MONEI.'
  );
  return (
    <>
      <IndexBackground sx={{left: {xl: '54%', lg: '60%'}}}>
        <Content>
          <SEO title={title} description={description} />
          <IndexSection>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Sell in France — expand your online business</Trans>
              </SectionHeader>
              <Trans parent="p">
                France is the third largest market in Europe and the sixth in the world. More than
                80% of internet users in France regularly buy online. So if you want to expand your
                online business internationally, selling in France is a must. Use MONEI to
                seamlessly accept cross-border payments.
              </Trans>
              <List listStyle="none" className="flex-list">
                <Trans parent="li">
                  <CheckGreen src={check_green} width={16} height={16} />︎
                  <span>
                    Accept the widest range of{' '}
                    <InternalPageLink slug="payment-methods">payment methods</InternalPageLink>
                  </span>
                </Trans>
                <Trans parent="li">
                  <CheckGreen src={check_green} width={16} height={16} />︎{' '}
                  <span>
                    Easily{' '}
                    <PrismicPage slug="payment-method-integrations-ecommerce-platforms">
                      integrate
                    </PrismicPage>{' '}
                    with your e-commerce platform or custom website
                  </span>
                </Trans>
                <Trans parent="li">
                  <CheckGreen src={check_green} width={16} height={16} />︎{' '}
                  <span>
                    Save money on transaction fees with{' '}
                    <InternalPageLink slug="pricing">dynamic billing</InternalPageLink>
                  </span>
                </Trans>
              </List>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <img
              className="index-image"
              src={payment_gateway}
              alt="MONEI payment gateway"
              title="MONEI payment gateway"
            />
          </IndexSection>
        </Content>
      </IndexBackground>
      <Background>
        <Content sx={{paddingBottom: '24px'}}>
          <Section sx={{maxWidth: 800, textAlign: 'center'}} column>
            <SectionHeader underline sx={{marginTop: {sm: '36px'}}}>
              <Trans>
                Skip connecting with a separate payment gateway in France — use MONEI instead
              </Trans>
            </SectionHeader>
            <Trans parent="p">
              Grow your <BlogLink slug="cross-border-ecommerce">cross-border e-commerce</BlogLink>{' '}
              business by accepting payments from neighboring countries. Accepting payments in
              France is easy with MONEI as your payments partner. With one platform, you can
              continue running your online business in Spain and sell in France. No third-party
              integrations required to accept{' '}
              <BlogLink slug="cross-border-payments">cross-border payments</BlogLink>.
            </Trans>
          </Section>
          <CardsContainer>
            <Card padding="80px 24px 32px 24px">
              <CardImage src={security} alt="" style={{top: -50, width: 65}} />
              <CardWrapper>
                <Trans parent="h4">Secure payments</Trans>
                <Trans>
                  Get built-in{' '}
                  <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                    3D Secure 2.0
                  </BlogLink>
                  , PSD2, and <BlogLink slug="understanding-pci-compliance">PCI DSS</BlogLink> Level
                  1 compliance
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="80px 24px 32px 24px">
              <CardImage src={cards} alt="" style={{top: -48, width: 100}} />
              <CardWrapper>
                <Trans parent="h4">More payment methods</Trans>
                <Trans>
                  Accept{' '}
                  <InternalPageLink slug="payment-methods/credit-cards">
                    credit cards
                  </InternalPageLink>
                  , <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink>, and{' '}
                  <BlogLink slug="local-payment-methods">local payment methods</BlogLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="80px 24px 32px 24px">
              <CardImage src={dashboard} alt="" style={{top: -55, width: 100}} />
              <CardWrapper>
                <Trans parent="h4">Real-time data</Trans>
                <Trans>Review daily, weekly, monthly, or custom sorted sales analytics</Trans>
              </CardWrapper>
            </Card>
            <Card padding="80px 24px 32px 24px">
              <CardImage src={cart} alt="" style={{top: -65, width: 90}} />
              <CardWrapper>
                <Trans parent="h4">Subscriptions</Trans>
                <Trans>
                  Set up recurring billing with customizable{' '}
                  <InternalPageLink slug="features/recurring-payments">
                    subscription plans
                  </InternalPageLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="80px 24px 32px 24px">
              <CardImage src={person} alt="" style={{top: -65, width: 100}} />
              <CardWrapper>
                <Trans parent="h4">Custom checkout page</Trans>
                <Trans>
                  Build awareness and trust — use your branding and{' '}
                  <SupportLink slug="/categories/360003383258">domain</SupportLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="80px 24px 32px 24px">
              <CardImage src={clock} alt="" style={{top: -60, width: 90}} />
              <CardWrapper>
                <Trans parent="h4">24-hour settlements</Trans>
                <Trans>Get payouts quickly so you can reinvest revenue to grow your business</Trans>
              </CardWrapper>
            </Card>
          </CardsContainer>
          <SectionActions align="center">
            <SignUpButton variant="dark">
              <Trans>Join MONEI Now</Trans>
            </SignUpButton>
          </SectionActions>
        </Content>
      </Background>
      <Content fullWidth>
        <Section column sx={{maxWidth: '960px', textAlign: 'center', margin: 'auto', paddingBottom: '0'}}>
          <SectionHeader underline tagName="h3">
            <Trans>Accept the most popular payment methods in France</Trans>
          </SectionHeader>
          <Trans>
            Offer your French customers their preferred payment methods. Use MONEI to manage your
            entire payment stack from a single platform.
          </Trans>
        </Section>
        <PaymentCard>
          <PaymentMethods exclude={['bizum', 'multibanco']}/>
          <ButtonLink href="/payment-methods" variant="light">
            <Trans>Vew all payment methods</Trans>
          </ButtonLink>
        </PaymentCard>
      </Content>
      <Content>
        <Section sx={{textAlign: "center"}} column>
          <SectionHeader underline tagName="h3">
            <Trans>E-commerce platform integration</Trans>
          </SectionHeader>
          <Trans parent="p" style={{maxWidth: '960px', margin: 'auto'}}>
            Quickly and easily connect with all{' '}
            <PrismicPage slug="payment-method-integrations-ecommerce-platforms">
              major e-commerce platforms
            </PrismicPage>{' '}
            or use our Payments API to integrate with your custom-built website.
          </Trans>
          <Partners />
        </Section>
      </Content>

      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible'}} sx={{paddingTop: 0}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>Power your cross-border payments with MONEI</Trans>
          </SectionHeader>
          <SectionDescription>
            <Trans parent="p">
              Get started today (without obligation) to test integrations and payment methods.
            </Trans>
          </SectionDescription>
          <SectionActions>
            <SignUpButton variant="dark">
              <Trans>Open an Account</Trans>
            </SignUpButton>
          </SectionActions>
          <CtaImage src={baloon} />
        </GradientBox>
      </Content>
    </>
  );
};

export default PaymentGatewayFrance;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "payment-gateway-france"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
