import React from 'react';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {AnchorLink} from '../AnchorLink';
import {Language} from '../../../locales/types';
import {links} from '../../global.config';

const languageMap = {
  es: 'es-es',
  en: 'en-us',
  pt: 'en-us',
  de: 'en-us',
  fr: 'en-us',
  it: 'en-us',
  ca: 'es-es',

}

export const SupportLink: React.FC<{slug: string}> = ({slug, children}) => {
  const {language} = useI18next();

  return (
    <AnchorLink
      external
      href={`${links.supportUrl + languageMap[(language as Language) || 'en-us']}${slug}/`}>
      {children}
    </AnchorLink>
  );
};
